
import firebase from 'firebase/compat/app'; 
import 'firebase/compat/firestore'; 
import { getFirestore } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAjid6fE8QruUbubbPqhTdPCeRlLTRDPVw",
  authDomain: "suv-googpsi-24-0205.firebaseapp.com",
  projectId: "suv-googpsi-24-0205",
  storageBucket: "suv-googpsi-24-0205.appspot.com",
  messagingSenderId: "21299031006",
  appId: "1:21299031006:web:5fd0a67ee36aa4f95b8b4f",
  measurementId: "G-346TFDZZYT"
};

firebase.initializeApp(firebaseConfig);
const app = initializeApp(firebaseConfig);
const db = firebase.firestore();
const firestore = getFirestore(app);
const auth = firebase.auth();

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

export { auth, db };
export { firestore };
export default firebase;

