import React, { useState, useEffect } from 'react';
import { Table, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../firebaseConfig'; 
import ProjectEdit from './ProjectEdit';
import ProjectDelete from './ProjectDelete';
import ProjectRerun from './ProjectRerun';
import './ProjectTable.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowDown, faRotateRight, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';

const ProjectTable = ({ projects }) => {
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState({});
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRerunModal, setShowRerunModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(25);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    
    const fetchUserId = async () => {
      const user = auth.currentUser;
      if (user) {
        setUserId(user.uid);
      }
    };

    fetchUserId();
  }, []);

  useEffect(() => {
    const fetchProjectData = async () => {
      if (!userId) return;

      try {
        const newProjectData = {};
        for (const project of projects) {
          const urls = project.urls;

          if (urls.length > 0) {
            const firstUrl = urls[0];

            const desktopSnapshot = await db.collection(`users/${userId}/projects/${project.id}/pageSpeedData`)
              .where('url', '==', firstUrl)
              .orderBy('timestamp', 'desc')
              .limit(1)
              .get();

            const mobileSnapshot = await db.collection(`users/${userId}/projects/${project.id}/pageSpeedData`)
              .where('url', '==', firstUrl)
              .orderBy('timestamp', 'desc')
              .limit(1)
              .get();

            const desktopData = desktopSnapshot.docs.length > 0 ? desktopSnapshot.docs[0].data() : null;
            const mobileData = mobileSnapshot.docs.length > 0 ? mobileSnapshot.docs[0].data() : null;

            newProjectData[project.id] = {
              lastChecked: desktopData ? desktopData.timestamp.toDate() : 'N/A',
              mobileSpeed: mobileData ? mobileData.mobileSpeed : 'N/A',
              desktopSpeed: desktopData ? desktopData.desktopSpeed : 'N/A'
            };
          }
        }
        setProjectData(newProjectData);
      } catch (error) {
        console.error('Error fetching project data:', error);
      }
    };

    fetchProjectData();
  }, [projects, userId]);

  const handleViewClick = (projectId) => {
    navigate(`/detailed-report/${projectId}`, { state: { projectId } });
  };

  const getSpeedColor = (speed) => {
    if (speed >= 0 && speed <= 49) return 'red';
    if (speed >= 50 && speed <= 79) return 'orange';
    if (speed >= 80 && speed <= 100) return 'green';
    return 'black';
  };

  const formatDate = (date) => {
    if (!(date instanceof Date) || isNaN(date)) return 'N/A';

    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const weekday = date.toLocaleString('default', { weekday: 'long' });

    const suffix = (d) => {
      if (d > 3 && d < 21) return 'th';
      switch (d % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };

    return `${day}<sup>${suffix(day)}</sup> ${month} ${year}, ${weekday}`;
  };

  const handleEditClick = (projectId) => {
    setSelectedProjectId(projectId);
    setShowEditModal(true);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setSelectedProjectId(null);
  };

  const handleRerunClick = (projectId) => {
    setSelectedProjectId(projectId);
    setShowRerunModal(true);
  };

  const handleRerunModalClose = () => {
    setShowRerunModal(false);
    setSelectedProjectId(null);
  };

  const handleDeleteClick = (projectId) => {
    setSelectedProjectId(projectId);
    setShowDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
    setSelectedProjectId(null);
  };

  const toggleDropdown = (projectId) => {
    setDropdownOpen((prevState) => (prevState === projectId ? null : projectId));
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = projects.slice(indexOfFirstItem, indexOfLastItem);

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage(prev => prev - 1);
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(projects.length / itemsPerPage)) setCurrentPage(prev => prev + 1);
  };

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Project Name</th>
            <th>Last Checked</th>
            <th>Mobile Speed</th>
            <th>Desktop Speed</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((project, index) => {
            const data = projectData[project.id] || {};
            const isDropdownOpen = dropdownOpen === project.id;

            return (
              <tr key={index}>
                <td><b>{project.name}</b> <br /> {project.urls[0]}</td>
                <td dangerouslySetInnerHTML={{ __html: data.lastChecked !== 'N/A' ? formatDate(data.lastChecked) : 'N/A' }} />
                <td style={{ color: getSpeedColor(data.mobileSpeed) }}>{data.mobileSpeed !== 'N/A' ? `${Math.round(data.mobileSpeed)}` : 'N/A'}</td>
                <td style={{ color: getSpeedColor(data.desktopSpeed) }}>{data.desktopSpeed !== 'N/A' ? `${Math.round(data.desktopSpeed)}` : 'N/A'}</td>
                <td>
                  <div className="action-container">
                    &nbsp;
                    <button className="button1" onClick={() => handleViewClick(project.id)}>View</button>
                    &nbsp; &nbsp;
                    <div style={{ display: 'inline-block', position: 'relative' }}>
                      <FontAwesomeIcon
                        icon={faCircleArrowDown}
                        style={{ color: 'black', cursor: 'pointer' }}
                        onClick={() => toggleDropdown(project.id)}
                      />
                      {isDropdownOpen && (
                        <div className="dropdown-menu show">
                          <Dropdown.Item onClick={() => handleRerunClick(project.id)}>
                            <FontAwesomeIcon icon={faRotateRight} style={{ color: 'blue', marginRight: '5px' }} />
                            Re-run
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleEditClick(project.id)}>
                            <FontAwesomeIcon icon={faPencil} style={{ color: 'yellow', marginRight: '5px' }} />
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleDeleteClick(project.id)}>
                            <FontAwesomeIcon icon={faTrash} style={{ color: 'red', marginRight: '5px' }} />
                            Delete
                          </Dropdown.Item>
                        </div>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <div className="pagination-controls">
        <button className="button2" onClick={handlePrevious} disabled={currentPage === 1}>Previous</button>
        <span>Page {currentPage} of {Math.ceil(projects.length / itemsPerPage)}</span>
        <button className="button2" onClick={handleNext} disabled={currentPage === Math.ceil(projects.length / itemsPerPage)}>Next</button>
      </div>

      {selectedProjectId && (
        <>
          <ProjectEdit
            show={showEditModal}
            handleClose={handleEditModalClose}
            projectId={selectedProjectId}
          />

          <ProjectRerun
            show={showRerunModal}
            handleClose={handleRerunModalClose}
            projectId={selectedProjectId}
          />

          <ProjectDelete
            show={showDeleteModal}
            handleClose={handleDeleteModalClose}
            projectId={selectedProjectId}
          />
        </>
      )}
    </>
  );
};

export default ProjectTable;
