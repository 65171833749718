import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { Tab, Tabs } from 'react-bootstrap';
import { FaMobileAlt, FaDesktop } from 'react-icons/fa';
import './Test.css';

axios.defaults.baseURL = 'https://www.onpageseopulse.com/';

const Test = () => {
  const [url, setUrl] = useState('');
  const [mobileSpeed, setMobileSpeed] = useState(null);
  const [desktopSpeed, setDesktopSpeed] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const fetchPageSpeedData = useCallback(async (urlList) => {
    setIsFetching(true);
    try {
      const fetchPromises = urlList.map(url =>
        axios.post('/api/pagespeed', { url })
      );
      const responses = await Promise.all(fetchPromises);

      const newResults = responses.map((response, index) => ({
        url: urlList[index],
        mobileSpeed: Math.round(response.data.mobileScore),
        desktopSpeed: Math.round(response.data.desktopScore),
      }));

      setMobileSpeed(newResults[0].mobileSpeed); 
      setDesktopSpeed(newResults[0].desktopSpeed); 

    } catch (error) {
    } finally {
      setIsFetching(false);
    }
  }, []);

  const handleSubmit = () => {
    if (url) {
      fetchPageSpeedData([url]);
    }
  };

  return (
    <div className="test-container">
      <center>
        <h2 className="heading">Test Here</h2>
      </center>
      <div className="input-container">
        <input
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Enter URL"
          className="url-input"
        />
        <button 
          onClick={handleSubmit} 
          disabled={isFetching} 
          className="box"
        >
          {isFetching ? 'Fetching...' : 'Submit'}
        </button>
      </div>

      {(mobileSpeed !== null || desktopSpeed !== null) && (
        <div className="results-container">
          <center>
            <h3 className="results-heading">Speed Results</h3>
            <div className="tabs-container">
              <center><Tabs defaultActiveKey="mobile" id="speed-tabs" className="mb-3">
                <Tab eventKey="mobile" title={<><FaMobileAlt /> Mobile Speed</>} className="tab-content">
                  {mobileSpeed !== null && (
                    <div className="speed-card">
                      <p className="speed-text">Mobile Speed: <span className="speed-value">{mobileSpeed}</span></p>
                    </div>
                  )}
                </Tab>
                <Tab eventKey="desktop" title={<><FaDesktop /> Desktop Speed</>} className="tab-content">
                  {desktopSpeed !== null && (
                    <center><div className="speed-card">
                      <p className="speed-text">Desktop Speed: <span className="speed-value">{desktopSpeed}</span></p>
                    </div></center>
                  )}
                </Tab>
              </Tabs></center>
            </div>
          </center>
        </div>
      )}
    </div>
  );
};

export default Test;
