import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import "./App.scss";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { db } from './firebaseConfig';
import firebase from 'firebase/compat/app';
import 'chart.js/auto';
import SpinnerProgressBar from './SpinnerProgressBar';
import { Link, useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Dropdown, DropdownButton, Modal, Button, Form } from 'react-bootstrap';
import AdditionalFeature from './components/AdditionalFeature';
import HistoricalDataAnalysis from './components/HistoricalDataAnalysis';
import AutoMode from './components/AutoMode';

function getColorAndStatus(score) {
  if (score <= 49) {
    return { color: 'red', status: 'Poor Speed' };
  } else if (score <= 79) {
    return { color: 'orange', status: 'Needs Improvement' };
  } else {
    return { color: 'green', status: 'Good' };
  }
}

function App() {
  const [urls, setUrls] = useState('');
  const [mainUrls, setMainUrls] = useState([]);
  const [results, setResults] = useState([]);
  const [autoMode, setAutoMode] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [schedule, setSchedule] = useState(''); 
  const [scheduleType, setScheduleType] = useState('');
  const [scheduleDetails, setScheduleDetails] = useState({ dateTime: '', dayOfWeek: '', dayOfMonth: '', time: '' });
  const [scheduleMessage, setScheduleMessage] = useState('');
  const autoModeRef = useRef(autoMode);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [selectedProjectIds, setSelectedProjectIds] = useState([]);
  
    useEffect(() => {
      const unsubscribe = firebase.auth().onAuthStateChanged(setUser);
      return () => unsubscribe();
    }, []);

    const handleLogout = async () => {
      try {
        await firebase.auth().signOut();
        setUser(null);
        navigate('/');
      } catch (error) {
        console.error('Error logging out:', error);
      }
    };

    const fetchPageSpeedData = useCallback(async (urlList) => {
      if (!user) return;
    
      setIsFetching(true);
      try {
        const idToken = await user.getIdToken(); 
        const fetchPromises = urlList.map(url =>
          axios.post('/api/pagespeed', { url }, {
            headers: {
              'Authorization': `Bearer ${idToken}`, 
            }
          })
        );
        const responses = await Promise.all(fetchPromises);
    
        const newResults = responses.map((response, index) => ({
          url: urlList[index],
          mobileSpeed: Math.round(response.data.mobileScore),
          desktopSpeed: Math.round(response.data.desktopScore),
          timestamp: new Date().toISOString(),
        }));
    
        setResults(prevResults => [...prevResults, ...newResults]);
    
        if (user) {
          for (const result of newResults) {
            await db.collection(`users/${user.uid}/pageSpeedData`).add({
              url: result.url,
              mobileSpeed: result.mobileSpeed,
              desktopSpeed: result.desktopSpeed,
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            });
          }
        }
      } finally {
        setIsFetching(false);
      }
    }, [user]);
    

  useEffect(() => {
    autoModeRef.current = autoMode;
  }, [autoMode]);

  useEffect(() => {
    if (user) {
      const fetchProjects = async () => {
        try {
          const snapshot = await db.collection(`users/${user.uid}/projects`).get();
          const projects = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setMainUrls(projects);
        } catch (error) {
          console.error('Error fetching projects:', error);
        }
      };

      fetchProjects();
    }
  }, [user]);

  const handleProjectSelection = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
    setSelectedProjectIds(selectedOptions);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const urlList = urls.split('\n').map(url => url.trim()).filter(url => url);
    fetchPageSpeedData(urlList);
  };

  const handleExportCSV = () => {
    try {
      const headers = 'URLs,Mobile Speed,Desktop Speed\n';
      const rows = results.map(result => `${result.url},${Math.round(result.mobileSpeed)},${Math.round(result.desktopSpeed)}`).join('\n');
      const csv = headers + rows;
      const blob = new Blob([csv], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      const date = new Date().toISOString().split('T')[0]; 
      link.download = `custom_pagespeed_report_${date}.csv`;
      link.click();
    } catch (error) {
      console.error('Error exporting CSV:', error);
    }
  };

  const handleExportPDF = () => {
    try {
      const doc = new jsPDF();
      doc.autoTable({
        head: [['URL', 'Mobile Speed', 'Desktop Speed']],
        body: results.map(result => [result.url, Math.round(result.mobileSpeed), Math.round(result.desktopSpeed)]),
      });
      const date = new Date().toISOString().split('T')[0]; 
      doc.save(`custom_pagespeed_report_${date}.pdf`);
    } catch (error) {
      console.error('Error exporting PDF:', error);
    }
  };

  const handleScheduleSubmit = async (event) => {
    event.preventDefault();
    setAutoMode(true);

    try {
      const scheduleString = (() => {
        if (scheduleType === 'specific') {
          return `Specific Date/Time at ${scheduleDetails.dateTime}`;
        } else if (scheduleType === 'weekly') {
          return `Weekly on ${scheduleDetails.dayOfWeek} at ${scheduleDetails.time}`;
        } else if (scheduleType === 'monthly') {
          return `Monthly on ${scheduleDetails.dayOfMonth} at ${scheduleDetails.time}`;
        }
        return '';
      })();

      console.log('Schedule String:', scheduleString);

      const projectNamesPromises = selectedProjectIds.map(async (projectId) => {
        const projectSnapshot = await db.collection(`users/${user.uid}/projects`).doc(projectId).get();
        return projectSnapshot.data()?.name || 'Unknown Project';
      });

      const projectNames = await Promise.all(projectNamesPromises);
      setSchedule(scheduleString); 
      setScheduleMessage(`You have scheduled auto mode for ${projectNames.join(', ')} at ${scheduleString}`);

      if (user) {
        for (const projectId of selectedProjectIds) {
          await db.collection(`users/${user.uid}/schedules`).doc(projectId).set({ 
            projectId,
            scheduleType,
            scheduleDetails
          });
        }
      }
      
      setShowScheduleModal(false);
    } catch (error) {
      console.error('Error scheduling auto mode:', error);
    }
  };

  function formatDate(date) {
    const day = date.getDate();
    const ordinalSuffix = getOrdinalSuffix(day);
    const month = date.toLocaleString('en-US', { month: 'long' });
    const year = date.getFullYear();
    const time = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
    return `${day}${ordinalSuffix} ${month} ${year}, ${time}`;
  }

  function getOrdinalSuffix(day) {
    if (day > 3 && day < 21) return 'th'; 
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

  return (
    <div className="container mt-3 animated-container">
      <div className="header">
        <Link to="/">
          <img src="/logo.png" alt="Logo" className="logo" />
        </Link>
        <div className="auth-button">
          {user ? (
            <button onClick={handleLogout} className="logout-btn">Logout</button>
          ) : (
            <Link to="/login">
              <button className="login-btn">Login</button>
            </Link>
          )}
        </div>
      </div>
      <div>
        <div className="card shadow-sm">
          <div className="card-body">
            <center><h2 className="card-title text-center mb-4 animated-title">PageSpeed Analysis</h2></center>
            <form onSubmit={handleSubmit}>
              <div className="form-group1">
                <label htmlFor="urls">Enter website URLs (one per line)</label>
                <textarea
                  className="form-control animated-textarea"
                  id="urls"
                  value={urls}
                  onChange={(e) => setUrls(e.target.value)}
                  rows="10"
                  required={!autoMode}
                  disabled={autoMode}
                />
              </div>
              <br />
              <center>
                <button type="submit" className="glow-on-hover" disabled={autoMode}>Get Page Speed</button>
              </center>
            </form>
            {autoMode && (
              <div className="alert alert-info mt-3">
                Auto mode is active. Page Speed Insights section is disabled.
              </div>
            )}
            {isFetching && <SpinnerProgressBar value={50} />}
            {!autoMode && results.length > 0 && (
              <div className="mt-4">
                <div className="d-flex justify-content-end mb-3">
                  <DropdownButton
                    id="save-as-dropdown"
                    title="Download"
                    variant="purple"
                    className="btn-download btn-pill"
                  >
                    <Dropdown.Item onClick={handleExportCSV}>Download As CSV</Dropdown.Item>
                    <Dropdown.Item onClick={handleExportPDF}>Download As PDF</Dropdown.Item>
                  </DropdownButton>
                </div>
                {results.map((result, index) => (
                  <div key={index} className="result-card card mt-3">
                    <div className="card-body">
                      <h5 className="card-title">URL: {result.url}</h5>
                      <p>Checked On: {formatDate(new Date(result.timestamp))}</p>
                      <div className="row">
                        <div className="col-md-6 text-center">
                          <h6>Mobile Speed</h6>
                          <div style={{ width: '100px', height: '100px', margin: 'auto' }}>
                            <CircularProgressbar
                              value={result.mobileSpeed}
                              text={`${result.mobileSpeed}`}
                              styles={buildStyles({
                                pathColor: getColorAndStatus(result.mobileSpeed).color,
                                textColor: getColorAndStatus(result.mobileSpeed).color,
                              })}
                            />
                          </div>
                          <p style={{ color: getColorAndStatus(result.mobileSpeed).color }}>
                            {getColorAndStatus(result.mobileSpeed).status}
                          </p>
                          <p className="card-text">Mobile Speed: <span className="badge badge-success">{result.mobileSpeed}</span></p>
                        </div>
                        <div className="col-md-6 text-center">
                          <h6>Desktop Speed</h6>
                          <div style={{ width: '100px', height: '100px', margin: 'auto' }}>
                            <CircularProgressbar
                              value={result.desktopSpeed}
                              text={`${result.desktopSpeed}`}
                              styles={buildStyles({
                                pathColor: getColorAndStatus(result.desktopSpeed).color,
                                textColor: getColorAndStatus(result.desktopSpeed).color,
                              })}
                            />
                          </div>
                          <p style={{ color: getColorAndStatus(result.desktopSpeed).color }}>
                            {getColorAndStatus(result.desktopSpeed).status}
                          </p>
                          <p className="card-text">Desktop Speed: <span className="badge badge-success">{result.desktopSpeed}</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <br />
      <div className="container mt-3 animated-container">
        <AdditionalFeature />
      </div>
      <div className="container mt-3 animated-container">
        <HistoricalDataAnalysis />
      </div>
      <br />
      <div>
        <center>
          <AutoMode />
          {autoMode && schedule && (
            <div className="message-box mt-3">
              Scheduled auto mode for: {schedule}
            </div>
          )}
          {scheduleMessage && (
            <div className="message-box mt-3">
              {scheduleMessage}
            </div>
          )}
        </center>
        <SpinnerProgressBar isLoading={isFetching} />
      </div>
      <Modal show={showScheduleModal} onHide={() => setShowScheduleModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Schedule Auto Mode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleScheduleSubmit}>
            <Form.Group>
              <Form.Label>Select Projects</Form.Label>
              <Form.Control as="select" multiple value={selectedProjectIds} onChange={handleProjectSelection}>
                {mainUrls?.length > 0 ? (
                  mainUrls.map((project, index) => (
                    <option key={index} value={project.id}>{project.name}</option>
                  ))
                ) : (
                  <option value="">No Projects Available</option>
                )}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Choose Schedule Type</Form.Label>
              <Form.Control as="select" value={scheduleType} onChange={(e) => setScheduleType(e.target.value)}>
                <option value="">Select Schedule Type</option>
                <option value="specific">Specific Date/Time</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </Form.Control>
            </Form.Group>

            {scheduleType === 'specific' && (
              <Form.Group>
                <Form.Label>Specific Date/Time</Form.Label>
                <Form.Control type="datetime-local" value={scheduleDetails.dateTime} onChange={(e) => setScheduleDetails({ ...scheduleDetails, dateTime: e.target.value })} />
              </Form.Group>
            )}

            {scheduleType === 'weekly' && (
              <>
                <Form.Group>
                  <Form.Label>Day of the Week</Form.Label>
                  <Form.Control as="select" value={scheduleDetails.dayOfWeek} onChange={(e) => setScheduleDetails({ ...scheduleDetails, dayOfWeek: e.target.value })}>
                    <option value="0">Sunday</option>
                    <option value="1">Monday</option>
                    <option value="2">Tuesday</option>
                    <option value="3">Wednesday</option>
                    <option value="4">Thursday</option>
                    <option value="5">Friday</option>
                    <option value="6">Saturday</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Time</Form.Label>
                  <Form.Control type="time" value={scheduleDetails.time} onChange={(e) => setScheduleDetails({ ...scheduleDetails, time: e.target.value })} />
                </Form.Group>
              </>
            )}

            {scheduleType === 'monthly' && (
              <>
                <Form.Group>
                  <Form.Label>Day of the Month</Form.Label>
                  <Form.Control type="number" value={scheduleDetails.dayOfMonth} onChange={(e) => setScheduleDetails({ ...scheduleDetails, dayOfMonth: e.target.value })} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Time</Form.Label>
                  <Form.Control type="time" value={scheduleDetails.time} onChange={(e) => setScheduleDetails({ ...scheduleDetails, time: e.target.value })} />
                </Form.Group>
              </>
            )}

            <Button variant="primary" type="submit">Save Schedule</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default App;
