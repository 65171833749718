import React, { useState, useEffect } from 'react';
import { Container, Table, Dropdown } from 'react-bootstrap'; 
import { Line } from 'react-chartjs-2'; 
import { useParams } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { Timestamp } from 'firebase/firestore'; 
import { getAuth } from 'firebase/auth'; 
import './DetailedReportPage.css'; 
import ProjectRerun from './ProjectRerun'; 
import ProjectEdit from './ProjectEdit';
import ProjectDelete from './ProjectDelete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowDown, faRotateRight, faPencil, faTrash, faDownload } from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';

const DetailedReportPage = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [desktopSpeedReports, setDesktopSpeedReports] = useState({});
  const [mobileSpeedReports, setMobileSpeedReports] = useState({});
  const [selectedUrl, setSelectedUrl] = useState(null); 
  const [openDropdown, setOpenDropdown] = useState(null);
  const [chartData, setChartData] = useState(null); 
  const [showRerunModal, setShowRerunModal] = useState(false); 
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null); 

  const auth = getAuth(); 
  const user = auth.currentUser; 
  const userId = user ? user.uid : null; 

  useEffect(() => {
    const fetchProjectData = async () => {
      if (!userId) return; 

      try {
        const projectDoc = await db.collection(`users/${userId}/projects`).doc(projectId).get();
        if (projectDoc.exists) {
          const projectData = projectDoc.data();
          setProject(projectData);
          const urls = projectData.urls;

          setSelectedUrl(urls[0]);

          const desktopReports = {};
          const mobileReports = {};

          for (const url of urls) {
            const desktopSnapshot = await db.collection(`users/${userId}/projects/${projectId}/pageSpeedData`)
              .where('url', '==', url)
              .orderBy('timestamp', 'desc')
              .limit(1)
              .get();
            const mobileSnapshot = await db.collection(`users/${userId}/projects/${projectId}/pageSpeedData`)
              .where('url', '==', url)
              .orderBy('timestamp', 'desc')
              .limit(1)
              .get();

            const desktopData = desktopSnapshot.docs.length > 0 ? desktopSnapshot.docs[0].data() : null;
            const mobileData = mobileSnapshot.docs.length > 0 ? mobileSnapshot.docs[0].data() : null;

            desktopReports[url] = {
              desktopSpeed: desktopData ? Math.round(desktopData.desktopSpeed) : 'N/A',
              timestamp: desktopData ? desktopData.timestamp.toDate() : 'N/A'
            };

            mobileReports[url] = {
              mobileSpeed: mobileData ? Math.round(mobileData.mobileSpeed) : 'N/A',
              timestamp: mobileData ? mobileData.timestamp.toDate() : 'N/A'
            };
          }

          setDesktopSpeedReports(desktopReports);
          setMobileSpeedReports(mobileReports);

          const fetchHistoricalData = async () => {
            try {
              const lastYear = new Date();
              lastYear.setFullYear(lastYear.getFullYear() - 1);

              const startTimestamp = Timestamp.fromDate(lastYear);
              const endTimestamp = Timestamp.fromDate(new Date());

              const historicalSnapshot = await db.collection(`users/${userId}/projects/${projectId}/pageSpeedData`)
                .where('timestamp', '>=', startTimestamp)
                .where('timestamp', '<=', endTimestamp)
                .get();

              const historicalData = historicalSnapshot.docs.map(doc => doc.data());

              const groupedData = historicalData.reduce((acc, item) => {
                if (!acc[item.url]) {
                  acc[item.url] = { labels: [], mobile: [], desktop: [] };
                }
                const date = item.timestamp.toDate().toISOString().split('T')[0];
                acc[item.url].labels.push(date);
                acc[item.url].mobile.push(Math.round(item.mobileSpeed));
                acc[item.url].desktop.push(Math.round(item.desktopSpeed));

                return acc;
              }, {});

              const chartData = Object.keys(groupedData).map(url => ({
                url,
                data: {
                  labels: groupedData[url].labels,
                  datasets: [
                    {
                      label: `Mobile Speed (${url})`,
                      data: groupedData[url].mobile,
                      borderColor: 'rgba(75, 192, 192, 1)',
                      backgroundColor: 'rgba(75, 192, 192, 0.2)',
                      fill: true,
                    },
                    {
                      label: `Desktop Speed (${url})`,
                      data: groupedData[url].desktop,
                      borderColor: 'rgba(153, 102, 255, 1)',
                      backgroundColor: 'rgba(153, 102, 255, 0.2)',
                      fill: true,
                    }
                  ]
                }
              }));

              setChartData(chartData);
            } catch (error) {
              console.error('Error fetching historical data:', error);
            }
          };

          fetchHistoricalData();
        } else {
          console.error('Project not found');
        }
      } catch (error) {
        console.error('Error fetching project data:', error);
      }
    };

    fetchProjectData();
  }, [projectId, userId]);

  useEffect(() => {
    if (project && project.urls.length > 0) {
      setSelectedUrl(project.urls[0]);
    }
  }, [project]);

  const getSpeedColor = (speed) => {
    if (speed >= 0 && speed <= 49) return 'red';
    if (speed >= 50 && speed <= 79) return 'orange';
    if (speed >= 80 && speed <= 100) return 'green';
    return 'black';
  };

  const formatDate = (date) => {
    if (!(date instanceof Date) || isNaN(date)) return 'N/A';
  
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const weekday = date.toLocaleString('default', { weekday: 'long' });
  

    const getDaySuffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };
  
    return `${day}${getDaySuffix(day)} ${month} ${year}, ${weekday}`;
  };
  

  const toggleDropdown = (url) => {
    setOpenDropdown(openDropdown === url ? null : url);
  };

  const handleReRunClick = (projectId) => {
    setSelectedProjectId(projectId);
    setShowRerunModal(true);
  };

  const handleEditClick = (projectId) => {
    setSelectedProjectId(projectId);
    setShowEditModal(true);
  };

  const handleDeleteClick = (projectId) => {
    setSelectedProjectId(projectId);
    setShowDeleteModal(true);
  };

  const handleDownloadClick = () => {
    const doc = new jsPDF('p', 'pt'); 
    doc.setFontSize(10); 
    
    const projectName = project ? project.name : 'Project';
    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    doc.text(`Full Report Of ${projectName}`, doc.internal.pageSize.getWidth() / 2, 40, { align: 'center' });
  
    let currentY = 60;
    doc.setFontSize(6); 
  
    const columnTitles = ['URL', 'Last Checked', 'Mobile Speed', 'Desktop Speed'];
    const columnWidths = [260, 120, 65, 65]; 
  
    doc.setFillColor(200, 200, 200); 
    doc.rect(40, currentY, columnWidths.reduce((a, b) => a + b, 0), 20, 'F'); 
  
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(0, 0, 0); 

    columnTitles.forEach((title, index) => {
      const xPos = 40 + columnWidths.slice(0, index).reduce((a, b) => a + b, 0);
      doc.text(title, xPos + 5, currentY + 14); 
    });
  
    currentY += 30;
    
    doc.setFont('helvetica', 'normal');
    
    project.urls.forEach((url) => {
      const lastChecked = desktopSpeedReports[url]?.timestamp ? formatDate(desktopSpeedReports[url]?.timestamp) : 'N/A';
      const mobileSpeed = (mobileSpeedReports[url]?.mobileSpeed !== undefined) ? String(mobileSpeedReports[url]?.mobileSpeed) : 'N/A';
      const desktopSpeed = (desktopSpeedReports[url]?.desktopSpeed !== undefined) ? String(desktopSpeedReports[url]?.desktopSpeed) : 'N/A';
  
      const cellHeight = 20;
  
      doc.rect(40, currentY, columnWidths[0], cellHeight);
      doc.text(String(url), 45, currentY + 14);
  
      doc.rect(40 + columnWidths[0], currentY, columnWidths[1], cellHeight);
      doc.text(String(lastChecked), 45 + columnWidths[0], currentY + 14);
 
      doc.rect(40 + columnWidths[0] + columnWidths[1], currentY, columnWidths[2], cellHeight);
      doc.text(String(mobileSpeed), 45 + columnWidths[0] + columnWidths[1], currentY + 14);
  
      doc.rect(40 + columnWidths[0] + columnWidths[1] + columnWidths[2], currentY, columnWidths[3], cellHeight);
      doc.text(String(desktopSpeed), 45 + columnWidths[0] + columnWidths[1] + columnWidths[2], currentY + 14);
  
      currentY += cellHeight; 
    });
  
    doc.save(`full_report_of_${projectName}.pdf`);
  };


  const handleCloseRerunModal = () => {
    setShowRerunModal(false);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const chartDataForSelectedUrl = chartData ? chartData.find(data => data.url === selectedUrl) : null;

  return (
    <Container className="mt-4">
      <center><h3>{project ? project.name : 'Loading...'} - Detailed Reports</h3></center>
      {project && (
        <>
          <div className="dashboard">
            <div className="select-url-section">
              <center><h5>Select URL</h5></center>
              <select className="url-select" onChange={(e) => setSelectedUrl(e.target.value)} value={selectedUrl}>
                {project.urls.map((url, index) => (
                  <option key={index} value={url}>{url}</option>
                ))}
              </select>
            </div>
            <div className="dashboard-section">
              <h5>Last 1 Year Speed Report</h5>
              {chartDataForSelectedUrl ? (
                <Line data={chartDataForSelectedUrl.data} />
              ) : (
                <p>No data available for the selected URL.</p>
              )}
            </div>
          </div>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>URL</th>
                <th>Last Checked</th>
                <th>Mobile Speed</th>
                <th>Desktop Speed</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {project.urls.map((url, index) => (
                <tr key={index}>
                  <td>{url}</td>
                  <td dangerouslySetInnerHTML={{ __html: desktopSpeedReports[url]?.timestamp ? formatDate(desktopSpeedReports[url]?.timestamp) : 'N/A' }} />
                  <td style={{ color: getSpeedColor(mobileSpeedReports[url]?.mobileSpeed) }}>
                    {mobileSpeedReports[url]?.mobileSpeed || 'N/A'}
                  </td>
                  <td style={{ color: getSpeedColor(desktopSpeedReports[url]?.desktopSpeed) }}>
                    {desktopSpeedReports[url]?.desktopSpeed || 'N/A'}
                  </td>
                  <td>
                    <div className="dropdown-container">
                      <FontAwesomeIcon
                        icon={faCircleArrowDown}
                        onClick={() => toggleDropdown(url)}
                        className="dropdown-icon"
                      />
                      {openDropdown === url && (
                        <div className="dropdown-menu show">
                          <Dropdown.Item onClick={() => handleReRunClick(projectId)}>
                          <FontAwesomeIcon icon={faRotateRight} style={{ color: 'blue', marginRight: '5px' }} />
                          Rerun</Dropdown.Item>
                          <Dropdown.Item onClick={() => handleEditClick(projectId)}>
                          <FontAwesomeIcon icon={faPencil} style={{ color: 'yellow', marginRight: '5px' }} />
                          Edit</Dropdown.Item>
                          <Dropdown.Item onClick={() => handleDeleteClick(projectId)}>
                          <FontAwesomeIcon icon={faTrash} style={{ color: 'red', marginRight: '5px' }} />
                          Delete</Dropdown.Item>
                          <Dropdown.Item onClick={handleDownloadClick}>
                            <FontAwesomeIcon icon={faDownload} style={{ color: 'green', marginRight: '5px' }} />
                            Download
                          </Dropdown.Item>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
      {showRerunModal && <ProjectRerun show={showRerunModal} handleClose={handleCloseRerunModal} projectId={selectedProjectId} />}
      {showEditModal && <ProjectEdit show={showEditModal} handleClose={handleCloseEditModal} projectId={selectedProjectId} />}
      {showDeleteModal && <ProjectDelete show={showDeleteModal} handleClose={handleCloseDeleteModal} projectId={selectedProjectId} />}
    </Container>
  );
};

export default DetailedReportPage;
