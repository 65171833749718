import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import './HomePage.css';
import './HomePage.scss';
import Test from './test'; 

function HomePage() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(setUser);
    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
      setUser(null);
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleButtonClick = () => {
    if (user) {
      navigate('/app');
    } else {
      navigate('/login');
    }
  };

  return (
    <div className="homepage-container">
      <div className="logo-div">
        <Link to="/">
          <img src="/logo.png" alt="Logo" className="logo" />
        </Link>
        <div className="auth-button">
          {user ? (
            <button onClick={handleLogout} className="logout-btn">Logout</button>
          ) : (
            <Link to="/login">
              <button className="login-btn">Login</button>
            </Link>
          )}
        </div>
      </div>
      <div className="background-image">
        <div className="heading-container">
          <h1>Check Page Speed For Multiple URLs With One Go...</h1>
          <button
            className="btn-flip"
            data-back="Here"
            data-front="Click"
            onClick={handleButtonClick}
          >
          </button>
        </div>
      </div>
      <div className="test-container">
        <Test /> 
      </div>
    </div>
  );
}

export default HomePage;
