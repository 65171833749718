import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2'; 
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import { db } from '../firebaseConfig';
import { Timestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth'; 
import './HistoricalDataAnalysis.css'; 

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

const HistoricalDataAnalysis = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [data, setData] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [noDataMessage, setNoDataMessage] = useState('');

  const auth = getAuth(); 
  const user = auth.currentUser; 
  const userId = user ? user.uid : null; 

  useEffect(() => {
    const fetchProjects = async () => {
      if (!userId) return; 

      try {
        const projectCollection = await db.collection(`users/${userId}/projects`).get();
        const projectList = projectCollection.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setProjects(projectList);
      } catch (error) {
        console.error("Error fetching projects: ", error);
      }
    };

    fetchProjects();
  }, [userId]);

  const handleGetData = async () => {
    if (!selectedProject || !startDate || !endDate) {
      alert('Please select a project and set a date range.');
      return;
    }

    if (!userId) {
      alert('User not authenticated.');
      return;
    }

    try {
      const startTimestamp = Timestamp.fromDate(new Date(startDate));
      const endTimestamp = Timestamp.fromDate(new Date(endDate));
      console.log("Fetching data from:", startTimestamp.toDate(), "to:", endTimestamp.toDate()); 

      const response = await db.collection(`users/${userId}/projects/${selectedProject}/pageSpeedData`)
        .where('timestamp', '>=', startTimestamp)
        .where('timestamp', '<=', endTimestamp)
        .get();

      const fetchedData = response.docs.map(doc => doc.data());
      console.log("Fetched Data:", fetchedData); 

      if (fetchedData.length === 0) {
        setNoDataMessage("Sorry! You Don't Have Any Historical Data To Display.. Thank You!!!!");
        setData([]);
        setIsDataFetched(false);
        return;
      }

      const groupedData = fetchedData.reduce((acc, item) => {
        if (!acc[item.url]) {
          acc[item.url] = { labels: [], mobile: [], desktop: [] };
        }
        const date = item.timestamp.toDate().toISOString().split('T')[0];
        acc[item.url].labels.push(date);
        acc[item.url].mobile.push(item.mobileSpeed);
        acc[item.url].desktop.push(item.desktopSpeed);
        return acc;
      }, {});

      const chartData = Object.keys(groupedData).map(url => ({
        url,
        data: {
          labels: groupedData[url].labels,
          datasets: [
            {
              label: `Mobile Speed (${url})`,
              data: groupedData[url].mobile,
              borderColor: 'rgba(75, 192, 192, 1)',
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              fill: true,
            },
            {
              label: `Desktop Speed (${url})`,
              data: groupedData[url].desktop,
              borderColor: 'rgba(153, 102, 255, 1)',
              backgroundColor: 'rgba(153, 102, 255, 0.2)',
              fill: true,
            }
          ]
        }
      }));

      setData(chartData);
      setIsDataFetched(true);
      setNoDataMessage('');
    } catch (error) {
      console.error("Error fetching historical data: ", error);
      setIsDataFetched(false);
      setNoDataMessage('Error fetching data. Please try again later.');
    }
  };

  return (
    <div className="card-background">
      <div className="card-sm-center mt-4">
        <br />
        <h2 className="card-title text-center mb-4 animated-title">Historical Data Analysis</h2>
        <form onSubmit={(e) => e.preventDefault()} className="form">
          <div className="form-group">
            <label htmlFor="project" className="form-label">Select Project:</label>
            <select
              id="project"
              className="form-select"
              value={selectedProject}
              onChange={(e) => setSelectedProject(e.target.value)}
            >
              <option value="">--Select a Project--</option>
              {projects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.name || project.id} 
                </option>
              ))}
            </select>
            <hr />
          </div>
          <div className="form-group">
            <label htmlFor="startDate" className="form-label">Start Date:</label>
            <input
              type="date"
              id="startDate"
              className="form-input"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <hr />
          </div>
          <div className="form-group">
            <label htmlFor="endDate" className="form-label">End Date:</label>
            <input
              type="date"
              id="endDate"
              className="form-input"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <button type="button" className="glow-on-hover" onClick={handleGetData}>Get Historical Data</button>
        </form>
        {noDataMessage && <p className="alert alert-danger mt-3">{noDataMessage}</p>}
        {isDataFetched && data.length > 0 && (
          <div className="mt-4">
            {data.map((chart, index) => (
              <div key={index} className="result-card card mt-3">
                <br />
                <h5 className="chart-title">{`SpeedData for URL: ${chart.url}`}</h5>
                <Line data={chart.data} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default HistoricalDataAnalysis;
