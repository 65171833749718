import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const ProjectRerun = ({ show, handleClose, projectId }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(setUser);
    return () => unsubscribe();
  }, []);

  const handleReRun = async () => {
    if (!projectId) {
      console.error("Project ID is missing.");
      toast.error("Project ID is missing.");
      handleClose();
      return;
    }
    
    if (!user) {
      console.error("User is not authenticated.");
      toast.error("User is not authenticated.");
      handleClose();
      return;
    }

    setLoading(true);
    setError(null);
  
    try {
      const idToken = await user.getIdToken();

      const response = await axios.post(
        `https://www.onpageseopulse.com/api/re-run/${projectId}`,
        null,
        {
          headers: {
            'Authorization': `Bearer ${idToken}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success('Project data re-run successfully!');
      } else {
        throw new Error('Failed to re-run project.');
      }
    } catch (err) {
      console.error('Error re-running project:', err.response?.data || err.message); 
      setError(err.response?.data?.error || err.message); 
      toast.error(err.response?.data?.error || err.message); 
    } finally {
      setLoading(false);
      handleClose();
    }
  };
  
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Re-run Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" />
              <p className="mt-3">Processing...</p>
            </div>
          ) : (
            <p>Are you sure you want to re-run the data fetch for this project?</p>
          )}
          {error && <p className="text-danger">{error}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleReRun}
            disabled={loading}
          >
            {loading ? 'Running...' : 'Re-run'}
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default ProjectRerun;
