import React from 'react';
import './Spinner.css';

const SpinnerProgressBar = ({ isLoading }) => {
  return (
    <div className={`loading-overlay ${isLoading ? 'active' : ''}`}>
      <div className="spinner"></div>
    </div>
  );
};

export default SpinnerProgressBar;
