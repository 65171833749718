import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { auth } from '../firebaseConfig';  

const AutoMode = () => {
  const [showModal, setShowModal] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [scheduleType, setScheduleType] = useState('specific');
  const [specificDate, setSpecificDate] = useState('');
  const [specificTime, setSpecificTime] = useState('');
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(null);
  const [user, setUser] = useState(null);
  const [fetchingProjects, setFetchingProjects] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        try {
          const token = await user.getIdToken();
          await fetchProjects(token);
        } catch (error) {
          console.error('Error fetching projects:', error);
          toast.error('Error fetching projects. Please try again.');
        } finally {
          setFetchingProjects(false);
        }
      } else {
        setUser(null);
        setFetchingProjects(false);
        toast.error('User not authenticated');
      }
    });
    return () => unsubscribe();
  }, []);

  const fetchProjects = async (token) => {
    try {
      const response = await axios.get('/api/projects', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProjects(response.data);
    } catch (error) {
      throw error;
    }
  };

  const handleSelectProject = (projectId) => {
    setSelectedProjects((prevSelected) =>
      prevSelected.includes(projectId)
        ? prevSelected.filter((id) => id !== projectId)
        : [...prevSelected, projectId]
    );
  };

  const handleSelectAll = () => {
    setSelectedProjects(projects.map((project) => project.id));
  };

  const handleDeselectAll = () => {
    setSelectedProjects([]);
  };

  const handleScheduleChange = (e) => {
    setScheduleType(e.target.value);
    if (e.target.value !== 'specific') {
      setSpecificDate('');
    }
  };

  const resetForm = () => {
    setSelectedProjects([]);
    setScheduleType('specific');
    setSpecificDate('');
    setSpecificTime('');
    setWarning(null);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setWarning(null);

    if (selectedProjects.length === 0) {
      setWarning('At least one project must be selected.');
      setLoading(false);
      return;
    }

    if (!specificDate) {
      setWarning('Date or day of the week must be specified.');
      setLoading(false);
      return;
    }

    try {
      if (!user) {
        throw new Error('User not authenticated');
      }

      const token = await user.getIdToken();


      const projectsWithUser = selectedProjects.map((projectId) => ({
        userId: user.uid,
        projectId,
      }));

      const response = await axios.post(
        '/api/auto-mode',
        {
          projects: projectsWithUser,
          schedule: scheduleType,
          specificDate,
          specificTime,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        for (const project of projectsWithUser) {
          await axios.post(
            `/api/re-run/${project.projectId}`,
            { userId: project.userId },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }

        toast.success('Auto mode enabled and PageSpeed data fetched successfully!');
        setShowModal(false);
        resetForm();
      } else {
        throw new Error('Your auto-mode process may take time.');
      }
    } catch (err) {
      setWarning('The process is quite complex. Please be patient.');
      toast.warning('The process is quite complex. Please be patient.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <button className="button" onClick={() => setShowModal(true)}>
        Enable Auto Mode
        <div className="button__horizontal"></div>
        <div className="button__vertical"></div>
      </button>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Enable Auto Mode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Select Projects</Form.Label>
              <div className="d-flex mb-2">
                <Button variant="secondary" onClick={handleSelectAll}>
                  Select All
                </Button>
                &nbsp; &nbsp;
                <Button variant="secondary" onClick={handleDeselectAll} className="ml-2">
                  Deselect All
                </Button>
              </div>
              {fetchingProjects ? (
                <Spinner animation="border" />
              ) : (
                projects.map((project) => (
                  <Form.Check
                    key={project.id}
                    type="checkbox"
                    label={project.name}
                    value={project.id}
                    checked={selectedProjects.includes(project.id)}
                    onChange={() => handleSelectProject(project.id)}
                  />
                ))
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Schedule Type</Form.Label>
              <Form.Control as="select" value={scheduleType} onChange={handleScheduleChange}>
                <option value="specific">Specific Date & Time</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Time</Form.Label>
              <Form.Control
                type="time"
                value={specificTime}
                onChange={(e) => setSpecificTime(e.target.value)}
              />
            </Form.Group>

            {scheduleType === 'specific' && (
              <Form.Group>
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  value={specificDate}
                  onChange={(e) => setSpecificDate(e.target.value)}
                />
              </Form.Group>
            )}

            {scheduleType === 'weekly' && (
              <Form.Group>
                <Form.Label>Day of the Week</Form.Label>
                <Form.Control
                  as="select"
                  value={specificDate}
                  onChange={(e) => setSpecificDate(e.target.value)}
                >
                  <option value="">Select Day</option>
                  <option value="0">Sunday</option>
                  <option value="1">Monday</option>
                  <option value="2">Tuesday</option>
                  <option value="3">Wednesday</option>
                  <option value="4">Thursday</option>
                  <option value="5">Friday</option>
                  <option value="6">Saturday</option>
                </Form.Control>
              </Form.Group>
            )}

            {scheduleType === 'monthly' && (
              <Form.Group>
                <Form.Label>Date of the Month</Form.Label>
                <Form.Control
                  type="number"
                  min="1"
                  max="31"
                  value={specificDate}
                  onChange={(e) => setSpecificDate(e.target.value)}
                />
              </Form.Group>
            )}

            {warning && <p className="text-warning">{warning}</p>}
            <br />
            <Button variant="primary" onClick={handleSubmit} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default AutoMode;
