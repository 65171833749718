import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import DetailedReportPage from './components/DetailedReportPage';
import AutoModeStats from './components/AutoModeStats';
import App from './App';
import ProjectDetails from './components/ProjectDetails';  
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/app" element={<App />} />
        <Route path="/detailed-report/:projectId" element={<DetailedReportPage />} />
        <Route path="/auto-mode-stats" element={<AutoModeStats />} />
        <Route path="/project/:projectId" element={<ProjectDetails />} />
        <Route path="/login" element={<LoginPage />} /> 
        <Route path="/register" element={<RegisterPage />} /> 
      </Routes>
    </Router>
  );
};


export default AppRouter;
