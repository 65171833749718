import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './RegisterPage.css';

const RegisterPage = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();


  const db = firebase.firestore();

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      
      const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
      const user = userCredential.user;

  
      await db.collection('users').doc(user.uid).set({
        fullName: fullName,
        email: email,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      alert('Registration successful');
      navigate('/app');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="register-container">
      <Link to="/">   
        <center><img src="/logo.png" alt="Logo" className="logo" /></center>
      </Link> 
      <br />
      <h2>Register</h2>
      <form onSubmit={handleRegister} className="register-form">
        <div className="form-group1">
          <label>Full Name</label>
          <input 
            type="text" 
            value={fullName} 
            onChange={(e) => setFullName(e.target.value)} 
            required 
            className="form-input"
          />
        </div>
        <div className="form-group1">
          <label>Email ID</label>
          <input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
            className="form-input"
          />
        </div>
        <div className="form-group1">
          <label>Password</label>
          <div className="password-wrapper">
            <input 
              type={showPassword ? 'text' : 'password'} 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required 
              className="form-input"
            />
            <FontAwesomeIcon 
              icon={showPassword ? faEyeSlash : faEye} 
              className="eye-icon" 
              onClick={() => setShowPassword(!showPassword)}
            />
          </div>
        </div>
        <div className="form-group1">
          <label>Confirm Password</label>
          <div className="password-wrapper">
            <input 
              type={showConfirmPassword ? 'text' : 'password'} 
              value={confirmPassword} 
              onChange={(e) => setConfirmPassword(e.target.value)} 
              required 
              className="form-input"
            />
            <FontAwesomeIcon 
              icon={showConfirmPassword ? faEyeSlash : faEye} 
              className="eye-icon" 
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            />
          </div>
        </div>
        {error && <p className="error-message">{error}</p>}
        <br />
        <button type="submit" className="register-button">Register</button>
      </form>
    </div>
  );
};

export default RegisterPage;
