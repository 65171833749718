import React, { useState } from 'react';
import { Container, Form, Button, Table } from 'react-bootstrap';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';

const AutoModeStats = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reports, setReports] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!startDate || !endDate) {
      setErrorMessage('Please select both start and end dates.');
      return;
    }

    try {
      setLoading(true);
      setErrorMessage('');

      const startDateTimestamp = new Date(startDate + 'T00:00:00Z');
      const endDateTimestamp = new Date(endDate + 'T23:59:59Z');

      const autoModeCollection = collection(firestore, 'pageSpeedData');

      const q = query(
        autoModeCollection,
        where('timestamp', '>=', startDateTimestamp),
        where('timestamp', '<=', endDateTimestamp)
      );

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setReports({});
        setErrorMessage('No data available for the selected date range.');
      } else {
        const fetchedReports = [];
        querySnapshot.forEach((doc) => {
          fetchedReports.push(doc.data());
        });
        setReports(groupReportsByDate(fetchedReports));
      }
    } catch (error) {
      console.error('Error fetching reports:', error);
      setErrorMessage('Error fetching reports. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const groupReportsByDate = (reports) => {
    const groupedReports = {};
    reports.forEach((report) => {
      const date = formatDate(report.timestamp);
      if (!groupedReports[date]) {
        groupedReports[date] = [];
      }
      groupedReports[date].push(report);
    });
    return groupedReports;
  };

  const formatDate = (timestamp) => {
    const date = timestamp.toDate();
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const renderWholeNumber = (number) => {
    return Math.round(number);
  };

  const getSpeedColor = (speed) => {
    if (speed >= 0 && speed <= 49) {
      return 'text-danger';
    } else if (speed >= 50 && speed <= 79) {
      return 'text-warning'; 
    } else if (speed >= 80 && speed <= 100) {
      return 'text-success'; 
    } else {
      return ''; 
    }
  };

  return (
    <div>
      <Container className="mt-4 text-center">
      <h2>View Auto Mode Stats</h2>
  <div className="d-flex justify-content-center">
    <Form onSubmit={handleSubmit} className="w-20">
      <Form.Group controlId="startDate">
        <Form.Label>Start Date</Form.Label>
        <Form.Control type="date" value={startDate} onChange={handleStartDateChange} required />
      </Form.Group>
      <Form.Group controlId="endDate">
        <Form.Label>End Date</Form.Label>
        <Form.Control type="date" value={endDate} onChange={handleEndDateChange} required />
      </Form.Group>
      <br />
      <Button variant="primary" type="submit" disabled={loading} className="w-100">
        {loading ? 'Loading...' : 'Submit'}
      </Button>
    </Form>
  </div>
  {errorMessage && <p className="text-danger mt-2">{errorMessage}</p>}
      </Container>

      {Object.keys(reports).map((date, index) => (
        <Container key={index} className="mt-4">
          <h3>Auto Mode Report {index + 1} - {date}</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>URL</th>
                <th>Desktop Speed</th>
                <th>Mobile Speed</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(reports[date]) ? (
                reports[date].map((report, i) => (
                  <tr key={i}>
                    <td>{report.url}</td>
                    <td className={getSpeedColor(report.desktopSpeed)}>
                      {renderWholeNumber(report.desktopSpeed)}
                    </td>
                    <td className={getSpeedColor(report.mobileSpeed)}>
                      {renderWholeNumber(report.mobileSpeed)}
                    </td>
                    <td>{formatDate(report.timestamp)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No data available for this date.</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Container>
      ))}
    </div>
  );
};


export default AutoModeStats;
