import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { Container, Table } from 'react-bootstrap';

const ProjectDetails = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const projectDoc = await db.collection('projects').doc(projectId).get();
        if (projectDoc.exists) {
          setProject(projectDoc.data());
        }
      } catch (error) {
        console.error('Error fetching project:', error);
      }
    };

    fetchProject();
  }, [projectId]);

  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <h2>{project.name}</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>S. No.</th>
            <th>URL</th>
          </tr>
        </thead>
        <tbody>
          {project.urls.map((url, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{url}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default ProjectDetails;
