import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { db } from '../firebaseConfig';
import { getAuth } from 'firebase/auth'; 

const ProjectDelete = ({ show, handleClose, projectId }) => {
  const auth = getAuth(); 
  const user = auth.currentUser; 
  const userId = user ? user.uid : null; 

  const handleDelete = async () => {
    if (!userId) return; 
    try {
      await db.collection(`users/${userId}/projects`).doc(projectId).delete();
      handleClose();
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this project? This action cannot be undone.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProjectDelete;
