import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';  
import ProjectTable from './ProjectTable';

const AdditionalFeature = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [projectList, setProjectList] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [newProjectName, setNewProjectName] = useState('');
  const [newProjectUrls, setNewProjectUrls] = useState('');


  const db = firebase.firestore();
  const user = firebase.auth().currentUser;
  const userId = user ? user.uid : null;

  useEffect(() => {
    const fetchProjects = async () => {
      if (!userId) return;

      try {
        const projectsSnapshot = await db.collection('users').doc(userId).collection('projects').get();
        const projectsData = projectsSnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().name,
          urls: doc.data().urls || []
        }));
        setProjectList(projectsData);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, [userId, db]);

  useEffect(() => {
    const updateFirestore = async () => {
      if (!userId) return;

      try {
        const projectsSnapshot = await db.collection('users').doc(userId).collection('projects').get();
        const existingProjects = new Set(projectsSnapshot.docs.map(doc => doc.data().name));

        const newProjects = projectList.filter(project => !existingProjects.has(project.name));

        await Promise.all(newProjects.map(async project => {
          await db.collection('users').doc(userId).collection('projects').add({ name: project.name, urls: project.urls });
        }));
      } catch (error) {
        console.error('Error updating projects in Firestore:', error);
      }
    };

    if (projectList.length > 0 && userId) {
      updateFirestore();
    }
  }, [projectList, userId, db]);

  const handleAddProject = async () => {
    if (newProjectName.trim() !== '' && newProjectUrls.trim() !== '') {
      const urlsArray = newProjectUrls.split(',').map(url => url.trim()).filter(url => url);
      if (userId) {
        try {
          await db.collection('users').doc(userId).collection('projects').add({ name: newProjectName, urls: urlsArray });
          setNewProjectName('');
          setNewProjectUrls('');
          setShowAddModal(false);
        } catch (error) {
          console.error('Error adding project:', error);
        }
      }
    }
  };

  const filteredProjects = projectList.filter(project =>
    project.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container className="my-5">
      <Row className="mb-4 align-items-center">
        <Col md="auto">
          <h2>All Projects</h2>
        </Col>
        <Col md="auto" className="ml-2">
          <Form.Group controlId="searchProject">
            <Form.Control
              size="sm"
              type="text"
              placeholder="Search by project name..."
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button onClick={() => setShowAddModal(true)}>Add New Project</Button>
        </Col>
      </Row>

      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="newProjectName">
            <Form.Label>Project Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter project name"
              value={newProjectName}
              onChange={e => setNewProjectName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="newProjectUrls">
            <Form.Label>Project URLs (comma-separated)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter project URLs separated by commas"
              value={newProjectUrls}
              onChange={e => setNewProjectUrls(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleAddProject}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <ProjectTable projects={filteredProjects} /> 
    </Container>
  );
};

export default AdditionalFeature;
