import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { db } from '../firebaseConfig';
import { getAuth } from 'firebase/auth'; 

const ProjectEdit = ({ show, handleClose, projectId }) => {
  const [projectName, setProjectName] = useState('');
  const [urls, setUrls] = useState([]);
  const auth = getAuth(); 
  const user = auth.currentUser; 
  const userId = user ? user.uid : null; 

  useEffect(() => {
    if (projectId && userId) {
      const fetchProject = async () => {
        try {
          const doc = await db.collection(`users/${userId}/projects`).doc(projectId).get();
          if (doc.exists) {
            const data = doc.data();
            setProjectName(data.name);
            setUrls(data.urls || []);
          }
        } catch (error) {
          console.error('Error fetching project data:', error);
        }
      };
      fetchProject();
    }
  }, [projectId, userId]);

  const handleSave = async () => {
    if (!userId) return; 
    try {
      await db.collection(`users/${userId}/projects`).doc(projectId).update({
        name: projectName,
        urls
      });
      handleClose();
    } catch (error) {
      console.error('Error updating project:', error);
    }
  };

  const handleUrlChange = (index, event) => {
    const newUrls = [...urls];
    newUrls[index] = event.target.value;
    setUrls(newUrls);
  };

  const handleAddUrl = () => {
    setUrls([...urls, '']);
  };

  const handleRemoveUrl = (index) => {
    setUrls(urls.filter((_, i) => i !== index));
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formProjectName">
            <Form.Label>Project Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter project name"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>URLs</Form.Label>
            {urls.map((url, index) => (
              <div key={index} className="d-flex align-items-center mb-2">
                <Form.Control
                  type="text"
                  placeholder="Enter URL"
                  value={url}
                  onChange={(e) => handleUrlChange(index, e)}
                />
                <Button
                  variant="danger"
                  className="ml-2"
                  onClick={() => handleRemoveUrl(index)}
                >
                  Remove
                </Button>
              </div>
            ))}
            <Button
              variant="primary"
              onClick={handleAddUrl}
            >
              Add URL
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProjectEdit;
